//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue';
import CardLineCharts from '@/components/cards/LineCharts.vue';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
export default {
  name: 'AppsOccupancyDownload',
  components: {
    ConfirmModal: ConfirmModal,
    CardSimpleNumber: CardSimpleNumber,
    CardLineCharts: CardLineCharts
  },
  computed: {
    todayVisitIncrease: function todayVisitIncrease() {
      return this.stats.visitor_total - this.stats.visitor_total_yesterday;
    },
    avgOccupancyIncrease: function avgOccupancyIncrease() {
      var inc_percent = Math.round((this.stats.occupancy_avg - this.stats.occupancy_avg_yesterday) * 100 / Math.max(this.stats.occupancy_avg_yesterday, 1) * 100);
      inc_percent /= 100;
      return inc_percent;
    },
    triggerIncrease: function triggerIncrease() {
      return 0;
    }
  },
  data: function data() {
    return {
      data_ready: false,
      loading: null,
      stats: {},
      cycle: null
    };
  },
  mounted: function mounted() {
    this.readData();
  },
  methods: {
    readData: function readData() {
      var data = this.$route.query.p;
      var data_decoded = decodeURIComponent(escape(window.atob(data)));
      var data_parsed = JSON.parse(data_decoded);
      this.stats = data_parsed.stats;
      this.cycle = data_parsed.cycle;
      this.data_ready = true;
    },
    onDownloadPdf: function onDownloadPdf() {
      var _this = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this.onPDF();
      });
    },
    onPDF: function onPDF() {
      var _this2 = this;

      this.loading = this.$loading({
        lock: true,
        text: this.$t('cloud.title.report_generating'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.now = new Date(); // const container = document.createElement('div');

      var container = this.$refs.ReportContainer; // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.

      html2canvas(container, {
        imageTimeout: 5000,
        useCORS: true
      }).then(function (canvas) {
        // container.appendChild(canvas)
        var img = canvas.toDataURL('image/png');
        var pdf = new JsPDF('l', 'mm', 'a4');
        pdf.addImage(img, 'JPEG', 5, 5, 287, 200);

        var filename = _this2.$utility.GetDateTimeStr("occupancy-$yyyy$mm$dd-$HH$MM$ss.pdf", _this2.now);

        pdf.save(filename);

        _this2.loading.close();
      });
    },
    onDownloadJpg: function onDownloadJpg() {
      var _this3 = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this3.onJpeg();
      });
    },
    onJpeg: function onJpeg() {
      var _this4 = this;

      this.loading = this.$loading({
        lock: true,
        text: this.$t('cloud.title.report_generating'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.now = new Date(); // const container = document.createElement('div');

      var container = this.$refs.ReportContainer; // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.

      html2canvas(container, {
        imageTimeout: 5000,
        useCORS: true
      }).then(function (canvas) {
        // container.appendChild(canvas)
        var img = canvas.toDataURL('image/png');

        var filename = _this4.$utility.GetDateTimeStr("occupancy-$yyyy$mm$dd-$HH$MM$ss.jpg", _this4.now);

        var link = document.createElement("a");
        link.setAttribute("href", img);
        link.setAttribute("download", filename);
        link.click();

        _this4.loading.close();
      });
    },
    onDownloadCsv: function onDownloadCsv() {
      var _this5 = this;

      this.$refs.ConfirmModal.open(null, function () {
        _this5.onCsv();
      });
    },
    onCsv: function onCsv() {
      var csv_array = [];
      var header_line = [];
      header_line.push(this.$t('cloud.title.log_at'));
      header_line.push(this.$t('cloud.title.occupancy_export_count'));
      header_line.push(this.$t('cloud.title.occupancy_in'));
      header_line.push(this.$t('cloud.title.occupancy_out'));

      for (var i = 0; i < this.stats.occupancy_counts.length; i++) {
        var data_line = {};
        var time = this.$utility.GetDateTimeStr("$yyyy-$mm-$dd $HH:$MM", new Date(this.stats.occupancy_counts[i].x));
        data_line[this.$t('cloud.title.log_at')] = time;
        var count = this.stats.occupancy_counts[i].value;
        data_line[this.$t('cloud.title.occupancy_export_count')] = count;
        var _in = this.stats.occupancy_in[i].value;
        var _out = this.stats.occupancy_out[i].value;
        data_line[this.$t('cloud.title.occupancy_in')] = _in;
        data_line[this.$t('cloud.title.occupancy_out')] = _out;
        csv_array.push(data_line);
      }

      this.$utility.DownloadCSV(csv_array, 'occupancy-$yyyy$mm$dd_$HH$MM$ss.csv');
    }
  }
};